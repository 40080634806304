import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import auth from './auth.middlware.js'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../views/Home.vue'),
    meta: {
      //middleware: [auth]
    }
  },
  {
    path: '/search',
    name: 'search',
    component: ()=>import('../views/search.vue'),
    meta: {
      //middleware: [auth]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: ()=>import('../views/profile.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/gamelist',
    name: 'gamelist',
    component: ()=>import('../views/gamelist.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/perfectmoney',
    name: 'perfectmoney',
    component: ()=>import('../views/perfectmoney.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/card',
    name: 'card',
    component: ()=>import('../views/card.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: ()=>import('../views/admin.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),


  },
  {
    path: '/game/:id',
    component: () => import('../views/game.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/modiriatform/:noe',
    component: () => import('../views/modiriatform.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/modiriatarchive/:noe',
    component: () => import('../views/modiriatarchive.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/modiriattarchive/:noe',
    component: () => import('../views/modiriattarchive.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/photokol/:noe',
    component: () => import('../views/photokol.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/formview/:sh',
    component: () => import('../views/formview.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/formviewphotokol/:sh',
    component: () => import('../views/formviewphotokol.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/formviewarchive/:sh',
    component: () => import('../views/formviewarchive.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/hesabdari/:noe',
    component: () => import('../views/hesabdari.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/profileedit',
    component: () => import('../views/profileedit.vue'),
    props:true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/tabadol',
    component: () => import('../views/tabadol.vue'),
    props: true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/crypto',
    component: () => import('../views/crypto.vue'),
    props: true,
    meta: {
      middleware: [auth]
    }
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const context = {
    to,
    from,
    next,
    store
  };
  return to.meta.middleware[0]({
    ...context
  })
});
export default router
