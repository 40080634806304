import {mapGetters} from 'vuex'

export default  {
    //component:{snackbar},
	data() {
        return {
            a:'',
        };
    },
    mounted(){

    },
	computed:{
		...mapGetters(['baseUrl','appToken','toast'])
	},
    methods: {
        helperGet(action,param) {
            let self = this;
            param = typeof param == "undefined"?{}:param;

            param = this.helperSerialize(param);
            let link = action+'?'+param;
            link = this.baseUrl+'/'+link;
            return axios.get(link).
            then(function (r) {
			  return r;
			}).catch(function (e) {
                self.$toast.error('خطای شبکه رخ داده')
            })
        },
        helperPost(action,param) {
            let self = this;
            param = typeof param == "undefined"?{}:param;
            param.token = this.appToken;
            let link = this.baseUrl+'/'+action;
            return axios.post(link,param,{
                headers: {
                    'Content-Type': 'application/json',
                }
            }).
            then(function (r) {
                return r;
            }).catch(function (e) {
                self.$toast.error('خطای شبکه رخ داده')
            })
        },
        helperSetting(){
            return this.helperGet('setting',{}).then(function (r) {
                if(r.data.result == "1"){
                    return r.data.data;
                }else{
                    return {}
                }
            })
        },
        helperSerialize:function(obj) {
            obj.token = this.appToken;
            var str = [];
            for (var p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        },
        helperExcerpt(str,len) {
            if(typeof str != "string"){
                return  str;
            }
            if (str.length > len) {
                return str = str.substring(0,len) + "...";
            }
            return str;
        },
        helperNumberFormat(number){
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number;
            var prec = !isFinite(+0) ? 0 : Math.abs(0);
            var sep = (typeof  ',' === 'undefined') ? ',' :  ',';
            var dec = (typeof '.' === 'undefined') ? '.' : '.';
            var s = '';
            var toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                    .toFixed(prec)
            };
            // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0')
            }
            return s.join(dec)
        },
        helperLoading(action){
            let l = document.getElementById("preloading");
            if(action == 'on'){
                l.style.display = 'flex';
            }else{
                setTimeout(function () {
                    l.style.display = 'none';
                },500)
            }
        },
        helperToast(obj){
            this.$store.commit('toast',obj)
        },
        addCart(obj){
            this.$store.commit('addCart',obj)
        },
        checkSum(obj){
            this.$store.commit('checkSum',obj)
        },
        deleteFromCart(key){
            this.$store.commit('deleteFromCart',key)
        },
        plusCart(key){
            this.$store.commit('plusCart',key)
        },
        minusCart(key){
            this.$store.commit('minusCart',key)
        },


    },// --End Methods--
    watch:{}
};