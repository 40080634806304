import store from '../store/index'
export default function auth ({ next, store }){
 if(!store.getters.auth.loggedIn){
     return next({
        name: 'login'
     })
 }
 if(store.getters.auth.data.token_expire.slice(0, 10) <= new Date().toISOString().slice(0, 10)){
        return next({
            name: 'login'
        })
 }
 return next()
}