import Vue from 'vue'
import VTextMarquee from 'vue-text-marquee';
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import myHelper from './mixins/helper'
import 'vue-toast-notification/dist/theme-sugar.css';
import './registerServiceWorker';
import 'leaflet/dist/leaflet.css';


import VueMoment from 'vue-moment';
import moment from "moment-jalaali";




Vue.use(VueMoment, {
  moment,


});


Vue.use(VTextMarquee);
Vue.config.productionTip = false;
Vue.use(vuetify, {
  rtl: true
});


Vue.mixin(myHelper); // بصورت سراسری تعریف شده

new Vue({
  vuetify,
  router,
  store,

  render: h => h(App)
}).$mount('#app');
