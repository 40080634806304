<template>
  <v-app id="App" >
    <v-main>
      
      <div class="" style="">
        <!-- <div id="preloading">
          <span style="font-size: 50px" class="mdi mdi-loading mdi-spin"></span>
          <span style="font-size: 13px">درحال بارگذاری ...</span>
        </div> -->
        <router-view/>
      </div>
      <v-snackbar 
        v-model="toast.show"
        style="top:-100px"
        :timeout="toast.timeout"
        :color="toast.color"
        :outlined="toast.outlined"
        >{{ toast.text }}<template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="helperToast({show:false})"><v-icon>mdi-close</v-icon></v-btn>
        </template>
      </v-snackbar>
      
    </v-main>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {mapActions} from 'vuex';
  
  export default {
    name: 'App',
    components: {
      mapActions,
      mapGetters,
    },
    
    data: () => ({
    }),
    computed:{
      ...mapGetters(['toast'])
    },
    mounted(){
      this.$vuetify.rtl = true;
      this.updateAuth();
      if(this.$store.getters.auth.loggedIn){
        this.$router.push({path:'/'})
      }
      let prefix = this.$store.getters.prefix;
      let items = window.localStorage.getItem(prefix+'cart');
      this.$store.commit('setCart',items); /* پر کردن سید خرید */
      
    },
    /* _headermounted() {
      this.$vuetify.rtl = true;
      this.updateAuth();
      if(this.$store.getters.auth.loggedIn)
      this.$router.push({path:'/'})
    }, */
    methods:{
      changeRTL () {
        this.$vuetify.rtl = true
      },
      updateAuth(){
        this.$store.dispatch("checkToken");
      },
    },
    
  }
</script>
<style >

@font-face {
  font-family:'iranyekan';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/iranyekan/iranyekan.eot');
  src: url('./assets/fonts/iranyekan/iranyekan.eot?#iefix') format("embedded-opentype"),
  url('./assets/fonts/iranyekan/iranyekan.woff') format("woff")
  /*url('./assets/fonts/iranyekan/iranyekan.html') format("woff2"),
  url('./assets/fonts/iranyekan/iranyekan.html') format("truetype");*/
}

  @import './assets/css/custom.css';

  #preloading{
    width: 100%;
    height: 100%;
    background: #ffffff6e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
</style>
